/* footer */
footer {
	background-color: #333333;
	color: #fff;
	padding-top: 30px;
	font-size: 15px;
	ul {
		@include ul-default;
	}
	h6, h2 {
        font-family: $regular;
        font-size: 16px;
		color: #fff;
        text-align: left;
		margin-bottom: 25px;
	}
	.footer__col {
		min-height: 195px;
		ul li {
			display: block;
			margin-bottom: 5px;
			a {
				color: #fff;
				&:hover {
					text-decoration: underline;
				}
			}
			&.active a {
				text-decoration: underline;
			}
		}
		&.col--nav {
			padding: 0 60px;
			border-left: 1px solid #999999;
			border-right: 1px solid #999999;
			margin-left: 15px;
		}
		&.col--contacts {
			padding: 0 30px;
			margin: 0 30px 0 0;
			border-right: 1px solid #999999;
			ul li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 10px;
				&:after {
					position: absolute;
					top: 1px;
					left: 0;
					color: #fff;
					font-family: 'fontAwesome';
					font-size: 20px;
				}
				&.location:after {
					content: '\f041';
				}
				&.phones:after {
					content: '\f095';
				}
				&.email:after {
					content: '\f0e0';
					font-size: 16px;
				}
				a {
					display: block;
				}
			}
		}
		&.col--socials {
			ul li {
				@include inline-block;
			}
		}
	}
	.container-fluid {
		background-color: #f9f2e2;
		padding: 15px 0;
		margin-top: 25px;
		p {
			color: #454545;
			margin: 0;
			@include inline-block;
		}
		.artmedia {
			float: right;
			position: relative;
			padding-right: 120px;
			width: 250px;
			@include inline-block;
			img {
				position: absolute;
				top: -3px;
				right: 0;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.footer__col {
			&.col--nav {
				padding: 0 30px;
			}
			&.col--contacts {
				padding: 0;
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 15px;
		.container-fluid {
			margin-top: 0px;
		}
		h6, h2 {
            margin-top: 15px;
			margin-bottom: 15px;
		}
		.footer__col {
			min-height: 1px;
			margin-bottom: 15px;
			&.col--nav, &.col--contacts {
				padding: 0;
				margin: 0;
				border: none;
			}
			.footer--logo {
				display: block;
				width: 120px;
				img {
					width: 100%;
				}
			}
		}
	}
	@media screen and (max-width: 520px) {
		> .container {
			text-align: center;
		}
        h2 {
            text-align: center;
        }
		.footer__col {
			.footer--logo {
				margin: 0 auto;
			}
			ul li {
				text-align: left;
				display: table;
				margin: 2px auto;
			}
			.socials {
				ul li {
					margin: 2px 5px;
				}
			}
		}
		.container-fluid {
			p {
				display: block;
				text-align: center;
			}
			.artmedia {
				display: block;
				float: none;
				margin: 5px auto 0;
			}
		}
	}
}
/* footer */