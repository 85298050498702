/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSansRegular.eot');
    src: url('../fonts/OpenSansRegular.eot') format('embedded-opentype'),
         url('../fonts/OpenSansRegular.woff2') format('woff2'),
         url('../fonts/OpenSansRegular.woff') format('woff'),
         url('../fonts/OpenSansRegular.ttf') format('truetype'),
         url('../fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
}
@font-face {
    font-family: 'opensans_bold';
    src: url('../fonts/opensans_bold.eot');
    src: url('../fonts/opensans_bold.eot') format('embedded-opentype'),
         url('../fonts/opensans_bold.woff2') format('woff2'),
         url('../fonts/opensans_bold.woff') format('woff'),
         url('../fonts/opensans_bold.ttf') format('truetype'),
         url('../fonts/opensans_bold.svg#opensans_bold') format('svg');
}
@font-face {
    font-family: 'OpenSansItalic';
    src: url('../fonts/OpenSansItalic.eot');
    src: url('../fonts/OpenSansItalic.eot') format('embedded-opentype'),
         url('../fonts/OpenSansItalic.woff2') format('woff2'),
         url('../fonts/OpenSansItalic.woff') format('woff'),
         url('../fonts/OpenSansItalic.ttf') format('truetype'),
         url('../fonts/OpenSansItalic.svg#OpenSansItalic') format('svg');
}
@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSansLight.eot');
    src: url('../fonts/OpenSansLight.eot') format('embedded-opentype'),
         url('../fonts/OpenSansLight.woff2') format('woff2'),
         url('../fonts/OpenSansLight.woff') format('woff'),
         url('../fonts/OpenSansLight.ttf') format('truetype'),
         url('../fonts/OpenSansLight.svg#OpenSansLight') format('svg');
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/RobotoRegular.eot');
    src: url('../fonts/RobotoRegular.eot') format('embedded-opentype'),
         url('../fonts/RobotoRegular.woff2') format('woff2'),
         url('../fonts/RobotoRegular.woff') format('woff'),
         url('../fonts/RobotoRegular.ttf') format('truetype'),
         url('../fonts/RobotoRegular.svg#RobotoRegular') format('svg');
}

@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/RobotoBold.eot');
    src: url('../fonts/RobotoBold.eot') format('embedded-opentype'),
         url('../fonts/RobotoBold.woff2') format('woff2'),
         url('../fonts/RobotoBold.woff') format('woff'),
         url('../fonts/RobotoBold.ttf') format('truetype'),
         url('../fonts/RobotoBold.svg#RobotoBold') format('svg');
}


@font-face {
    font-family: 'ptserif';
    src: url('../fonts/ptserif.eot');
    src: url('../fonts/ptserif.eot') format('embedded-opentype'),
         url('../fonts/ptserif.woff2') format('woff2'),
         url('../fonts/ptserif.woff') format('woff'),
         url('../fonts/ptserif.ttf') format('truetype'),
         url('../fonts/ptserif.svg#ptserif') format('svg');
}

@font-face {
    font-family: 'PTSansBold';
    src: url('../fonts/PTSansBold.eot');
    src: url('../fonts/PTSansBold.eot') format('embedded-opentype'),
         url('../fonts/PTSansBold.woff2') format('woff2'),
         url('../fonts/PTSansBold.woff') format('woff'),
         url('../fonts/PTSansBold.ttf') format('truetype'),
         url('../fonts/PTSansBold.svg#PTSansBold') format('svg');
}



$regular: 'OpenSansRegular';
$bold: 'opensans_bold';
$light: 'OpenSansLight';
$italic: 'OpenSansItalic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:before, *:after {
	display: block;
	line-height: 1;
}

body {
	margin: 0;
	background-color: #f9f2e2;
	font-family: $regular;
	color: #222222;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 1199px) {
		padding-top: 88px;
	}
}

h1 {
	font-size: 42px;
	font-family: $bold;
	color: #454545;
	display: block;
	text-align: center;
	padding-bottom: 30px;
	margin-bottom: 25px;
	position: relative;
	line-height: 1;
	&:after {
		content: '';
		width: 65px;
		height: 4px;
		background-color: #ffe400;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
	}
	@media screen and (max-width: 991px) {
		font-size: 32px!important;
		padding-bottom: 15px;
		margin-bottom: 15px;
		a {
			font-size: 32px;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 28px!important;
		a {
			font-size: 28px;
		}
	}
}
h2 {
	font-size: 38px;
	font-family: $bold;
	color: #454545;
	display: block;
	margin-top: 0px;
	margin-bottom: 30px;
	text-transform: uppercase;
	text-align: center;
	a {
		font-size: 38px!important;
		text-transform: uppercase;
		font-family: $bold;
		color: #454545;
		display: block;
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		margin-bottom: 15px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-size: 24px;
	font-family: $bold;
	color: #454545;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-size: 22px;
	font-family: $bold;
	color: #454545;
	text-transform: uppercase;
}
h5 {
	font-size: 18px;
	color: #454545;
	font-family: $bold;
}
h6 {
	font-size: 16px;
	text-transform: uppercase;
	color: #454545;
}
a {
	color: #222222;
	transition: 350ms;
	&:focus {
		color: #282727;
		text-decoration: none;
	}
	&:hover {
		color: #ffe400;
	}
}
input, textarea, button {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 18px;
	padding-bottom: 50px;
	textarea, input {
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		padding: 5px 15px;
		border: 1px solid #c6c6c6;
	}
	a,p {
		font-size: 18px;
		text-decoration: none;
	}
	@media screen and (max-width: 1199px) {
		padding-top: 0;
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
		padding-bottom: 25px;
		p,a {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 0px;
	}
}
.main {
	a:hover {
		text-decoration: underline;
	}
	.container {
		padding-top: 40px;
		padding-bottom: 15px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	padding: 6px 40px!important;
	font-size: 16px;
	color: #000!important;
	background-color: #ffe400!important;
	cursor: pointer;
	width: auto!important;
	border-radius: 0px;
	border: 1px solid #ffe400!important;
	line-height: 1;
	transition: 350ms;
	&:focus, &:active {
		color: #000;
		background-color: #ffe400;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #000!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		padding-left: 35px;
		position: relative;
		margin-bottom: 5px;
		font-size: 20px;
		text-align: left;
		&:before {
			content: '\f00c';
			position: absolute;
			left: 0;
			top: 0px;
			font-family: 'fontAwesome';
			font-size: 22px;
			color: #ffe400;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
			padding-left: 30px;
			&:before  {
				font-size: 20px;
			}
		}
	}
}

.socials {
	ul {
		@include ul-default;
		li {
			margin: 0 9px 0 0;
			@include inline-block;
			a {
				display: block;
				width: 35px;
				height: 35px;
				color: #ffffff;
				border-radius: 100%;
				text-align: center;
				padding: 6px 0 0 0;
				box-sizing: border-box;
				line-height: 1;
				i {
					font-size: 22px;
					&.fa-facebook {
						font-size: 18px;
						margin-top: 3px;
					}
					&.fa-vk {
						font-size: 18px;
						margin-top: 3px;
					}
				}
				&:hover {
					opacity: .7;
				}
			}
			&:nth-of-type(1) a {
				background-color: #00adef;
			}
			&:nth-of-type(2) a {
				background-color: #3b579d;
			}
			&:nth-of-type(3) a {
				background: linear-gradient(#4662c9 10%, #b5399b, #ffd854 90%);
			}
			&:nth-of-type(4) a {
				background-color: #4c75a3;
			}
		}
	}
}

/* header */
header {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 9;
	color: #fff;
	font-size: 18px;
	.container-fluid {
		min-height: 38px;
		background-color: #ffe400;
		margin-top: 80px;
		.container {
			position: relative;
		}
	}
	ul {
		@include ul-default;
	}
	a:hover {
		text-decoration: none;
	}

	/* header socials */
	.socials {
		position: absolute;
		left: 50px;
		top: 20px;
	}
	/* header socials */

	/* header location */
	.location {
		padding-left: 25px;
		position: relative;
		position: absolute;
		top: 15px;
		left: 50%;
		margin-left: -570px;
		&:after {
			content: '\f041';
			position: absolute;
			top: 0;
			left: 0;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #fff;
		}
	}
	/* header location */
	
	/* header phones */
	.phones {
		position: absolute;
		top: 15px;
		right: 50%;
		margin-right: -570px;
		i {
			position: absolute;
			top: 2px;
			left: 0;
			font-size: 22px;
			color: #ffe400;
            &.fa-phone {
                font-size: 20px;
            }
		}
		ul {
			li {
                position: relative;
                padding-left: 30px;
				display: block;
				a {
					color: #fff;
					span {
						font-family: $bold;
					}
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	/* header phones */

	/* header logo */
	.logo {
		display: block;
		width: 115px;
		height: 115px;
		position: absolute;
		top: 40px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 1;
		img {
			display: block;
			width: 100%;
		}
	}
	/* header logo */

	/* header email */
	.email {
		position: absolute;
		top: 25px;
		right: 50px;
		color: #fff;
		border-bottom: 1px dashed #fff;
		&:after {
			content: '\f0e0';
			position: absolute;
			top: 3px;
			left: -25px;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #fff;
		}
		&:hover {
			text-decoration: none;
			border-bottom: 1px dashed transparent;
		}
	}
	/* header email */

	/* header nav */
	.nav {
		position: relative;
		@include inline-block;
		&:after {
			content: '';
			display: block;
			width: 1px;
			background-color: #949090;
			position: absolute;
			top: 4px;
			bottom: 4px;
			right: 0;
		}
		> ul {
			> li {
				position: relative;
				margin: 0 35px 0 0;
				@include inline-block;
				> a {
					display: block;
					height: 38px;
					position: relative;
					font-family: $bold;
					font-size: 17px;
					color: #000;
					padding: 7px 0 5px;
					box-sizing: border-box;
					&:before {
						content: '';
						height: 1px;
						width: 100%;
						background-color: #000;
						position: absolute;
						bottom: 8px;
						left: 0;
						transition: 400ms;
						opacity: 0;
					}
					&:hover {
						text-decoration: none;
						&:before {
							opacity: 1;
						}
					}
				} 
				&.active > a:before {
					opacity: 1;
				}
				ul {
					display: none;
					background-color: #ffe400;
					position: absolute;
					left: 0;
					top: 38px;
					box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
					li {
						display: block;
						a {
							display: block;
							font-size: 16px;
							color: #000;
							padding: 10px 20px;
							line-height: 1;
                            white-space: nowrap;
							&:hover {
                                background-color: #ffe400;
								text-decoration: underline;
							}
						}
						&.active a {
							text-decoration: underline;
						}
					}
				}
				&.submenu {
					> a {
						padding-right: 15px;
						&:after {
							content: '';
							width: 0;
							height: 0;
							border-top: 6px solid #000;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							position: absolute;
							right: 0;
							top: 16px;
						}
					}
				}
				&:nth-child(4) {
					margin-right: 270px;
				}
				&:last-child {
					margin-right: 25px;
				}
			}
		}
	}
	/* header nav */

	/* header search */
	.search--btn {
		display: block;
		position: absolute;
		top: 8px;
		right: 80px;
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #000;
		&:after {
			content: '\f002';
		}
		&.active {
			color: #fff;
		}
	}
	.search {
		display: none;
		width: 300px;
		position: absolute;
		right: 15px;
		top: 38px;
		background-color: #fff;
		padding-right: 25px;
		border: 1px solid #ffe400;
		input[type="text"] {
			width: 100%;
			border: none;
			padding: 5px 15px;
			font-size: 16px;
			box-sizing: border-box;
			outline: none;	
		}
		.close {
			display: block;
			position: absolute;
			right: 5px;
			top: 6px;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			opacity: 1;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	/* header search */

	/* header langs */
	.langs {
		max-height: 26px;
		position: absolute;
		top: 4px;
		right: 15px;
		overflow: hidden;
		padding: 5px 25px 5px 5px;
		transition: 400ms;
		&:after {
			content: '';
			border-top: 6px solid #000;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			position: absolute;
			top: 11px;
			right: 5px;
		}
		ul {
			line-height: 1;
			li {
				margin-bottom: 4px;
				a {
					font-size: 18px;
					color: #000;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
				&.active a {
					color: #000;
					text-decoration: none;
				}
			}
		}
		&:hover {
			max-height: 150px;
			background-color: rgba(255, 228, 0, 0.9);
		}
	}
	/* header langs */

	&.header--page {
		background-color: #f9f2e2;
		color: #222222;
		position: fixed;
		.location, .phones, .email{
			color: #222222;
			&:after {
				color: #ffe400;
			}
			a {
				color: #222222;
			}
		}
		.email:hover {
			border-bottom: 1px dashed #c5c6c6;
		}
	}

	@media screen and (max-width: 1650px) {
		.socials {
			left: 15px;
		}
		.email {
			right: 15px;
		}
	}

	@media screen and (max-width: 1580px) {
		.location {
			left: 250px;
			margin-left: 0;
		}
		.phones {
			margin-right: 0;
			right: 250px;
		}
	}

	@media screen and (max-width: 1199px) {
		position: fixed;
		background-color: #fff;
		color: #222222;
		.container-fluid {
			margin-top: 50px;
		}

		.logo {
			width: 80px;
			height: 80px;
			top: 4px;
		}

		.location, .phones, .email{
			color: #222222;
			&:after {
				color: #ffe400;
			}
			a {
				color: #222222;
			}
			ul li a {
				color: #222222;
			}
		}
		.email {
			top: 12px;
			right: 50px;
			&:hover {
				border-bottom: 1px dashed #c5c6c6;
			}
		}
		.socials, .location {
			display: none;
		}

		.phones {
			display: none;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			background-color: rgba(255, 228, 0, 0.9);
			padding: 0;
			text-align: center;
			box-sizing: border-box;
			padding: 15px;
			z-index: 1;
			&:after {
				display: none;
			}
		}
		.phones--btn {
			display: block;
			position: absolute;
			top: 12px;
			right: 15px;
			font-family: 'fontAwesome';
			font-size: 25px;
			color: #ffe400;
			&:after {
				content: '\f095'
			}
		}

		.nav {
			display: none;
			&:after {
				display: none;
			}
			> ul {
				> li {
					display: block;
					padding: 0 8px;
					margin: 0px;
					> a {
						display: table;
					}
					&:first-child {
						margin-top: 35px;
					}
					&:nth-child(4) {
						margin-right: 0;
					}
					&:last-child {
						margin: 0;
					}
					ul {
						position: relative;
						top: 0;
						left: 0;
						width: 100%;
                        box-shadow: none;
                        li a {
                            white-space: inherit;
                            padding-left: 5px;
                            padding-right: 5px;
                        }
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 15px;
			top: 4px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #000;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.langs {
			right: auto;
			left: 12px;
			top: -38px;
		}

		.search--btn {
			top: -35px;
			right: auto;
			left: 70px;
			&.active {
				color: #000;
			}
		}
		.search {
			width: 100%;
			right: 0;
		}
	}
	@media screen and (max-width: 768px) {
		.email {
			width: 22px;
			height: 22px;
			overflow: hidden;
			color: transparent!important;
			top: 10px;
			right: auto;
			left: 15px;
			&:after {
				left: 0;
				font-size: 22px;
			}
		}
		.langs {
			top: 4px;
			left: auto;
			right: 35px;
		}
		.search--btn {
			top: 7px;
			left: auto;
			right: 10px;
		}

	}
}

/* header */

.preload {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffe400;
	z-index: 999;
	.preload__block {
		display: table;
		width: 100%;
		height: 100%;
		position: relative;
		.preload__block__img {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			height: 100%;
			position: relative;
			text-align: center;
			.preload__block__img__bg {
				width: 250px;
				animation-name: preloadBg;
				animation-direction: alternate;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				opacity: 0.7;
			}
			.preload__block__img__logo {
				width: 200px;
				height: 200px;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin: -100px auto 0;
				animation-name: preloadLogo;
				animation-direction: normal;
				animation-duration: 3s;
				animation-iteration-count: infinite;
			}
		}
	}
}

[data-lang="kz"] {
    @media screen and (min-width: 1200px) {
        header {
            .nav>ul>li {
                margin: 0 20px 0 0;
                &:nth-child(4) {
                    margin-right: 150px;
                }
            }
        }
    }
}

@keyframes preloadBg {
	0% {
		transform: scale(1);
		opacity: 0.3;
	}
	25% {
		transform: scale(1.2);
		opacity: 1;
	}
	50% {
		transform: scale(1);
		opacity: 0.3;
	}
	75% {
		transform: scale(1.2);
		opacity: 1;
	}
	0% {
		transform: scale(1);
		opacity: 0.3;
	}
}
@keyframes preloadLogo {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(360deg);
		margin-top: -100px;
	}
	
	85% {
		margin-top: -120px;
	}
	88% {
		margin-top: -100px;
	}
	91% {
		margin-top: -120px;
	}
	94% {
		margin-top: -100px;
	}
	97% {
		margin-top: -110px;
	}
	100% {
		margin-top: -100px;
		transform: rotate(360deg);
	}
}