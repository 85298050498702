/* page content */
.page {
	padding-top: 118px;
	font-size: 15px;
	// background: url(../img/img-38.png) no-repeat center top;
	background-size: 1024px;
	&.company {
		background-image: none;
	}
	.news1, .news {
		.date {
			font-size: 15px;
			color: #000;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #ffe400;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #ffe400;
		}
	}
	h1 {
		font-size: 28px;
	}
	h3 {
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	h4 {
		text-align: center;
		margin: 25px 0;
	}
	a {
		color: #222222;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #ffe400;
			text-decoration: underline;
		}
	}
	p,a {
		font-size: 15px;
	}
	.quotes {
		position: relative;
		padding: 5px 0 5px 50px;
		overflow: hidden;
		font-family: $italic;
		font-weight: bold;
		margin: 25px 0;
		&:after {
			content: '';
			width: 23px;
			height: 21px;
			position: absolute;
			top: 0;
			left: 10px;
			font-size: 20px;
			background: url(../img/quotes.png) no-repeat center center;
		}
	}
	.services .services__list {
		margin-top: 40px;
		.services__list__col a  {
			font-size: 20px!important;
			text-decoration: none!important;
		}
	}
	.company--img {
		max-width: 530px;
		position: relative;
		margin: 0 auto;
		img {
			width: 100%;
		}
		&:after {
			content: '';
			position: absolute;
			width: 150%;
			height: 150%;
			background: url(../img/img-37.png) no-repeat center center;
			background-size: contain;
			top: 50%;
			left: 50%;
			margin-top: -75%;
			margin-left: -75%;
			animation-name: servicesImg;
			animation-direction: alternate;
			animation-duration: 3s;
			animation-iteration-count: infinite;
			opacity: 1;
		}
	}
	@media screen and (max-width: 1199px) {
		padding-top: 0;
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		h1 {
			font-size: 24px;
		}
		p, a {
			font-size: 16px;
		}
		.news1, .news {
			.date {
				font-size: 16px;
				color: #ffe400;
			}
		}
		.company--img {
			&:after {
				width: 110%;
				height: 110%;
				margin: -55% 0 0 -55%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 25px;
		.company--img {
			max-width: 100%;
			&:after {
				display: none;
			}
		}
		.gallery--height {
			td img {
				width: 100%;
				height: auto;
				margin: 0 auto;
			}
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	margin: 40px 0 0 0;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #7d7d7d;
			line-height: 1;
			@include inline-block;
			a {
				text-decoration: none;
				color: #454545;
				font-size: 16px;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #454545;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	@media screen and (max-width: 767px) {
		font-size: 12px;
		ul li {
			&:after {
				font-size: 12px;
				margin-top: 1px;
			}
			a {
				font-size: 12px;
			}
		}
	}
}
/* breadcrumbs */

/* page about */
.about__check--list {
	text-align: center;
	.check--list {
		width: 400px;
		margin: 0 5px 0 5px;
		@include inline-block;
	}
	@media screen and (max-width: 768px) {
		.check--list {
			width: 100%;
		}
	}
}
/* page about */

/* page services */
.services__col {
	display: block;
	position: relative;
	overflow: hidden;
	text-decoration: none!important;
	margin-bottom: 30px;
	img {
		display: block;
		width: 100%;
		filter: grayscale(100%);
		transition: 350ms;
	}
	.services__col__title {
		display: table;
        width: 100%;
        height: 58px;
        padding: 5px 5px;
        background-color: rgba(0,0,0,1);
		position: relative;
		text-align: center;
		overflow: hidden;
		h5 {
			display: table-cell;
            width: 100%;
            vertical-align: middle;
			font-family: $bold;
			font-size: 18px;
			color: #ffe400;
			margin: 0 auto;
		}
	}
	.btn {
		display: none;
		width: 170px!important;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin: -20px auto 0;
		opacity: 0;
	}
	&:hover {
		.btn {
			opacity: 0.8;
		}
		img {
			filter: grayscale(0);
			transform: scale(1.1);
		}
	}
	&.last {
		padding-top: 50px;
		pointer-events: none;
		img {
			width: auto;
			max-width: 100%;
			filter: grayscale(0);
			margin: 0 auto;
		}
	}
	@media screen and (max-width: 991px) {
		.services__col__title h5 {
			min-height: 0px;
		}
		img {
			filter: grayscale(0%);
		}
		.btn {
			opacity: 0.8;
		}
	}
}
/* page services */

/* page services unit */
.services--unit__for {
	position: relative;
	&:after {
		content: '\f00e';
		font-family: 'fontAwesome';
		font-size: 25px;
		color: #fff;
		position: absolute;
		right: 20px;
		bottom: 20px;
	}
	.slick-slide {
		outline: none;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
		}
	}
}

.services--unit__nav {
	margin: 30px -10px 30px;
	padding: 0 115px;
	.slick-slide {
		height: 90px;
		margin: 0 10px;
		position: relative;
		outline: none;
		cursor: pointer;
		overflow: hidden;
		border: 2px solid transparent;
		transition: 300ms;
		&:hover, &.slick-current {
			border: 2px solid #ffe400;
		}
		img {
			display: block;
			width: 100%;
		}
	}
	.slick-arrow {
		display: block;
		width: 35px;
		height: 35px;
		padding: 0px;
		overflow: hidden;
		transition: 350ms;
		line-height: 1;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			display: block;
			font-family: 'fontAwesome';
			font-size: 35px;
			color: #0f0f0f;
			text-align: center;
			transition: 300ms;
		}
		&.slick-prev {
			left: 65px;
			&:after {
				content: '\f104';
				margin-left: -2px;
			}
		}
		&.slick-next {
			right: 65px;
			&:after {
				content: '\f105';
				margin-right: -2px;
			}
		}
		&:hover {
			&:after {
				color: #ffe400;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		padding: 0 50px;
		.slick-arrow {
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			height: 80px;
		}
	}
	@media screen and (max-width: 767px) {
		.slick-slide {
			height: 120px;
		}
	}
	@media screen and (max-width: 640px) {
		.slick-slide {
			height: 90px;
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 60px;
		}
	}
	@media screen and (max-width: 380px) {
		.slick-slide {
			height: 45px;
			margin: 0 5px;
		}
	}
}

.cat--unit__info {
	padding: 60px 50px;
	background-color: #f5f5f5;
	color: #454545;
	font-size: 17px;
	h4 {
		margin: 0 0 15px 0;
		text-align: left;
		color: #454545;
	}
	ul {
		position: relative;
		@include ul-default;
		&:after {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			background-color: #e0e0e0;
			margin: 35px 0;
		}
		li {
			position: relative;
			padding-right: 70px;
			margin: 15px 0;
			&:after {
				content: '';
				height: 1px;
				background-color: #c3c1c1;
				position: absolute;
				left: 0;
				right: 0;
				top: 21px;
			}
			strong {
				display: inline;
				position: relative;
				background-color: #f5f5f5;
				z-index: 1;
			}
			span {
				position: absolute;
				top: 0;
				right: 0;
				background-color: #f5f5f5;
				z-index: 1;
				@include inline-block;
			}
		}
		&:last-child:after {
			display: none;
		}
	}
	@media screen and (max-width: 1199px) {
		padding: 30px 25px;
	}
	@media screen and (max-width: 991px) {
		padding: 20px 15px;
	}
}

.video {
	padding-top: 25px;
	h3 {
		text-align: center;
	}
}
/* page services unit */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 40px;
				height: 40px;
				display: block;
				border: 1px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 10px 2px 0px;
				outline: none;
				color: #222222;
				border: 1px solid #ffe400;
				line-height: 1;
				&:hover {
					background-color: #ffe400;
					border: 1px solid #ffe400;
					color: #222222;
				}
			}
			&.active {
				a {
					background-color: #ffe400;
					border: 1px solid #ffe400;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					border: 1px solid transparent;
					background-color: transparent!important;
					transition: 0ms;
					&:hover {
						border: 1px solid transparent;
						background-color: transparent!important;
						color: #ffe400;
					}
					&:after {
						display: block;
						font-family: 'fontAwesome';
						font-size: 22px;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -12px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					content:'\f104';
				} 
			}
			&.next a {
				&:after {
					content: '\f105';
				} 
			}
		}
	}
}
/* page pagination */

/* page contacts */
.contacts__left {
	font-size: 18px;
	> ul {
		@include ul-default;
		li {
			position: relative;
			padding-left: 30px;
			margin-bottom: 15px;
			&:after {
				position: absolute;
				top: 1px;
				left: 0;
				color: #ffe400;
				font-family: 'fontAwesome';
				font-size: 24px;
			}
			&.location:after {
				content: '\f041';
			}
			&.phones:after {
				content: '\f095';
			}
			&.email:after {
				content: '\f0e0';
				font-size: 18px;
				top: 3px;
			}
			a {
				text-decoration: none;
				font-size: 18px;
			}
		}
	}
	.socials {
		margin-top: 25px;
	}
}

.form {
	display: block;
	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%;
		background-color: transparent;
		margin-bottom: 10px;
	}
	.btn {
		font-family: $bold;
		float: right;
	}
}
.map {
	@media screen and (max-width: 991px) {
		> ymaps {
			height: 250px!important;
		}
	}
}
.ymaps-2-1-62-map {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: grayscale(100%);
}
/* page contacts */

/* modal */
.modal {
	.modal-content {
		border-radius: 0px;
		h4 {
			margin: 0;
		}
		.close {
			position: absolute;
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			opacity: 1;
			right: 10px;
			top: 10px;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content h3 {
			font-size: 20px;
		}
	}
}
#modal--map {
	width: 600px;
	@media screen and (max-width: 991px) {
		width: 100%;
		height: 300px;
		overflow: hidden;
		> ymaps {
			height: 100%!important;
		}
	}
}
/* modal */

/* page content */