/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
@font-face {
  font-family: 'OpenSansRegular';
  src: url("../fonts/OpenSansRegular.eot");
  src: url("../fonts/OpenSansRegular.eot") format("embedded-opentype"), url("../fonts/OpenSansRegular.woff2") format("woff2"), url("../fonts/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSansRegular.ttf") format("truetype"), url("../fonts/OpenSansRegular.svg#OpenSansRegular") format("svg"); }

@font-face {
  font-family: 'opensans_bold';
  src: url("../fonts/opensans_bold.eot");
  src: url("../fonts/opensans_bold.eot") format("embedded-opentype"), url("../fonts/opensans_bold.woff2") format("woff2"), url("../fonts/opensans_bold.woff") format("woff"), url("../fonts/opensans_bold.ttf") format("truetype"), url("../fonts/opensans_bold.svg#opensans_bold") format("svg"); }

@font-face {
  font-family: 'OpenSansItalic';
  src: url("../fonts/OpenSansItalic.eot");
  src: url("../fonts/OpenSansItalic.eot") format("embedded-opentype"), url("../fonts/OpenSansItalic.woff2") format("woff2"), url("../fonts/OpenSansItalic.woff") format("woff"), url("../fonts/OpenSansItalic.ttf") format("truetype"), url("../fonts/OpenSansItalic.svg#OpenSansItalic") format("svg"); }

@font-face {
  font-family: 'OpenSansLight';
  src: url("../fonts/OpenSansLight.eot");
  src: url("../fonts/OpenSansLight.eot") format("embedded-opentype"), url("../fonts/OpenSansLight.woff2") format("woff2"), url("../fonts/OpenSansLight.woff") format("woff"), url("../fonts/OpenSansLight.ttf") format("truetype"), url("../fonts/OpenSansLight.svg#OpenSansLight") format("svg"); }

@font-face {
  font-family: 'RobotoRegular';
  src: url("../fonts/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular.eot") format("embedded-opentype"), url("../fonts/RobotoRegular.woff2") format("woff2"), url("../fonts/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular.ttf") format("truetype"), url("../fonts/RobotoRegular.svg#RobotoRegular") format("svg"); }

@font-face {
  font-family: 'RobotoBold';
  src: url("../fonts/RobotoBold.eot");
  src: url("../fonts/RobotoBold.eot") format("embedded-opentype"), url("../fonts/RobotoBold.woff2") format("woff2"), url("../fonts/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold.ttf") format("truetype"), url("../fonts/RobotoBold.svg#RobotoBold") format("svg"); }

@font-face {
  font-family: 'ptserif';
  src: url("../fonts/ptserif.eot");
  src: url("../fonts/ptserif.eot") format("embedded-opentype"), url("../fonts/ptserif.woff2") format("woff2"), url("../fonts/ptserif.woff") format("woff"), url("../fonts/ptserif.ttf") format("truetype"), url("../fonts/ptserif.svg#ptserif") format("svg"); }

@font-face {
  font-family: 'PTSansBold';
  src: url("../fonts/PTSansBold.eot");
  src: url("../fonts/PTSansBold.eot") format("embedded-opentype"), url("../fonts/PTSansBold.woff2") format("woff2"), url("../fonts/PTSansBold.woff") format("woff"), url("../fonts/PTSansBold.ttf") format("truetype"), url("../fonts/PTSansBold.svg#PTSansBold") format("svg"); }

*:before, *:after {
  display: block;
  line-height: 1; }

body {
  margin: 0;
  background-color: #f9f2e2;
  font-family: "OpenSansRegular";
  color: #222222;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1199px) {
    body {
      padding-top: 88px; } }

h1 {
  font-size: 42px;
  font-family: "opensans_bold";
  color: #454545;
  display: block;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 25px;
  position: relative;
  line-height: 1; }
  h1:after {
    content: '';
    width: 65px;
    height: 4px;
    background-color: #ffe400;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto; }
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 32px !important;
      padding-bottom: 15px;
      margin-bottom: 15px; }
      h1 a {
        font-size: 32px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 28px !important; }
      h1 a {
        font-size: 28px; } }

h2 {
  font-size: 38px;
  font-family: "opensans_bold";
  color: #454545;
  display: block;
  margin-top: 0px;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center; }
  h2 a {
    font-size: 38px !important;
    text-transform: uppercase;
    font-family: "opensans_bold";
    color: #454545;
    display: block; }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 30px;
      margin-bottom: 15px; }
      h2 a {
        font-size: 30px !important; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 24px; }
      h2 a {
        font-size: 24px !important; } }

h3 {
  font-size: 24px;
  font-family: "opensans_bold";
  color: #454545; }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-size: 22px;
  font-family: "opensans_bold";
  color: #454545;
  text-transform: uppercase; }

h5 {
  font-size: 18px;
  color: #454545;
  font-family: "opensans_bold"; }

h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #454545; }

a {
  color: #222222;
  transition: 350ms; }
  a:focus {
    color: #282727;
    text-decoration: none; }
  a:hover {
    color: #ffe400; }

input, textarea, button {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 18px;
  padding-bottom: 50px; }
  .main textarea, .main input, .page textarea, .page input {
    font-family: "OpenSansRegular";
    font-size: 16px;
    background-color: #fff;
    padding: 5px 15px;
    border: 1px solid #c6c6c6; }
  .main a, .main p, .page a, .page p {
    font-size: 18px;
    text-decoration: none; }
  @media screen and (max-width: 1199px) {
    .main, .page {
      padding-top: 0; } }
  @media screen and (max-width: 991px) {
    .main, .page {
      font-size: 16px;
      padding-bottom: 25px; }
      .main p, .main a, .page p, .page a {
        font-size: 16px; } }
  @media screen and (max-width: 768px) {
    .main, .page {
      padding-bottom: 0px; } }

.main a:hover {
  text-decoration: underline; }

.main .container {
  padding-top: 40px;
  padding-bottom: 15px; }
  @media screen and (max-width: 1170px) {
    .main .container {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .main .container {
      padding-top: 15px;
      padding-bottom: 15px; } }

.btn {
  display: table;
  padding: 6px 40px !important;
  font-size: 16px;
  color: #000 !important;
  background-color: #ffe400 !important;
  cursor: pointer;
  width: auto !important;
  border-radius: 0px;
  border: 1px solid #ffe400 !important;
  line-height: 1;
  transition: 350ms; }
  .btn:focus, .btn:active {
    color: #000;
    background-color: #ffe400; }
  .btn:hover, .btn.active {
    background-color: #fff !important;
    color: #000 !important; }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    padding-left: 35px;
    position: relative;
    margin-bottom: 5px;
    font-size: 20px;
    text-align: left; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      left: 0;
      top: 0px;
      font-family: 'fontAwesome';
      font-size: 22px;
      color: #ffe400; }
  @media screen and (max-width: 991px) {
    .check--list li {
      font-size: 16px;
      padding-left: 30px; }
      .check--list li:before {
        font-size: 20px; } }

.socials ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .socials ul li {
    margin: 0 9px 0 0;
    display: inline-block;
    vertical-align: top; }
    .socials ul li a {
      display: block;
      width: 35px;
      height: 35px;
      color: #ffffff;
      border-radius: 100%;
      text-align: center;
      padding: 6px 0 0 0;
      box-sizing: border-box;
      line-height: 1; }
      .socials ul li a i {
        font-size: 22px; }
        .socials ul li a i.fa-facebook {
          font-size: 18px;
          margin-top: 3px; }
        .socials ul li a i.fa-vk {
          font-size: 18px;
          margin-top: 3px; }
      .socials ul li a:hover {
        opacity: .7; }
    .socials ul li:nth-of-type(1) a {
      background-color: #00adef; }
    .socials ul li:nth-of-type(2) a {
      background-color: #3b579d; }
    .socials ul li:nth-of-type(3) a {
      background: linear-gradient(#4662c9 10%, #b5399b, #ffd854 90%); }
    .socials ul li:nth-of-type(4) a {
      background-color: #4c75a3; }

/* header */
header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
  color: #fff;
  font-size: 18px;
  /* header socials */
  /* header socials */
  /* header location */
  /* header location */
  /* header phones */
  /* header phones */
  /* header logo */
  /* header logo */
  /* header email */
  /* header email */
  /* header nav */
  /* header nav */
  /* header search */
  /* header search */
  /* header langs */
  /* header langs */ }
  header .container-fluid {
    min-height: 38px;
    background-color: #ffe400;
    margin-top: 80px; }
    header .container-fluid .container {
      position: relative; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header a:hover {
    text-decoration: none; }
  header .socials {
    position: absolute;
    left: 50px;
    top: 20px; }
  header .location {
    padding-left: 25px;
    position: relative;
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -570px; }
    header .location:after {
      content: '\f041';
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #fff; }
  header .phones {
    position: absolute;
    top: 15px;
    right: 50%;
    margin-right: -570px; }
    header .phones i {
      position: absolute;
      top: 2px;
      left: 0;
      font-size: 22px;
      color: #ffe400; }
      header .phones i.fa-phone {
        font-size: 20px; }
    header .phones ul li {
      position: relative;
      padding-left: 30px;
      display: block; }
      header .phones ul li a {
        color: #fff; }
        header .phones ul li a span {
          font-family: "opensans_bold"; }
        header .phones ul li a:hover {
          text-decoration: none; }
  header .logo {
    display: block;
    width: 115px;
    height: 115px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1; }
    header .logo img {
      display: block;
      width: 100%; }
  header .email {
    position: absolute;
    top: 25px;
    right: 50px;
    color: #fff;
    border-bottom: 1px dashed #fff; }
    header .email:after {
      content: '\f0e0';
      position: absolute;
      top: 3px;
      left: -25px;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #fff; }
    header .email:hover {
      text-decoration: none;
      border-bottom: 1px dashed transparent; }
  header .nav {
    position: relative;
    display: inline-block;
    vertical-align: top; }
    header .nav:after {
      content: '';
      display: block;
      width: 1px;
      background-color: #949090;
      position: absolute;
      top: 4px;
      bottom: 4px;
      right: 0; }
    header .nav > ul > li {
      position: relative;
      margin: 0 35px 0 0;
      display: inline-block;
      vertical-align: top; }
      header .nav > ul > li > a {
        display: block;
        height: 38px;
        position: relative;
        font-family: "opensans_bold";
        font-size: 17px;
        color: #000;
        padding: 7px 0 5px;
        box-sizing: border-box; }
        header .nav > ul > li > a:before {
          content: '';
          height: 1px;
          width: 100%;
          background-color: #000;
          position: absolute;
          bottom: 8px;
          left: 0;
          transition: 400ms;
          opacity: 0; }
        header .nav > ul > li > a:hover {
          text-decoration: none; }
          header .nav > ul > li > a:hover:before {
            opacity: 1; }
      header .nav > ul > li.active > a:before {
        opacity: 1; }
      header .nav > ul > li ul {
        display: none;
        background-color: #ffe400;
        position: absolute;
        left: 0;
        top: 38px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
        header .nav > ul > li ul li {
          display: block; }
          header .nav > ul > li ul li a {
            display: block;
            font-size: 16px;
            color: #000;
            padding: 10px 20px;
            line-height: 1;
            white-space: nowrap; }
            header .nav > ul > li ul li a:hover {
              background-color: #ffe400;
              text-decoration: underline; }
          header .nav > ul > li ul li.active a {
            text-decoration: underline; }
      header .nav > ul > li.submenu > a {
        padding-right: 15px; }
        header .nav > ul > li.submenu > a:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 6px solid #000;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          position: absolute;
          right: 0;
          top: 16px; }
      header .nav > ul > li:nth-child(4) {
        margin-right: 270px; }
      header .nav > ul > li:last-child {
        margin-right: 25px; }
  header .search--btn {
    display: block;
    position: absolute;
    top: 8px;
    right: 80px;
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #000; }
    header .search--btn:after {
      content: '\f002'; }
    header .search--btn.active {
      color: #fff; }
  header .search {
    display: none;
    width: 300px;
    position: absolute;
    right: 15px;
    top: 38px;
    background-color: #fff;
    padding-right: 25px;
    border: 1px solid #ffe400; }
    header .search input[type="text"] {
      width: 100%;
      border: none;
      padding: 5px 15px;
      font-size: 16px;
      box-sizing: border-box;
      outline: none; }
    header .search .close {
      display: block;
      position: absolute;
      right: 5px;
      top: 6px;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #000;
      opacity: 1; }
      header .search .close:after {
        content: '\f00d'; }
      header .search .close:hover {
        opacity: 0.7; }
  header .langs {
    max-height: 26px;
    position: absolute;
    top: 4px;
    right: 15px;
    overflow: hidden;
    padding: 5px 25px 5px 5px;
    transition: 400ms; }
    header .langs:after {
      content: '';
      border-top: 6px solid #000;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      top: 11px;
      right: 5px; }
    header .langs ul {
      line-height: 1; }
      header .langs ul li {
        margin-bottom: 4px; }
        header .langs ul li a {
          font-size: 18px;
          color: #000;
          text-decoration: underline; }
          header .langs ul li a:hover {
            text-decoration: none; }
        header .langs ul li.active a {
          color: #000;
          text-decoration: none; }
    header .langs:hover {
      max-height: 150px;
      background-color: rgba(255, 228, 0, 0.9); }
  header.header--page {
    background-color: #f9f2e2;
    color: #222222;
    position: fixed; }
    header.header--page .location, header.header--page .phones, header.header--page .email {
      color: #222222; }
      header.header--page .location:after, header.header--page .phones:after, header.header--page .email:after {
        color: #ffe400; }
      header.header--page .location a, header.header--page .phones a, header.header--page .email a {
        color: #222222; }
    header.header--page .email:hover {
      border-bottom: 1px dashed #c5c6c6; }
  @media screen and (max-width: 1650px) {
    header .socials {
      left: 15px; }
    header .email {
      right: 15px; } }
  @media screen and (max-width: 1580px) {
    header .location {
      left: 250px;
      margin-left: 0; }
    header .phones {
      margin-right: 0;
      right: 250px; } }
  @media screen and (max-width: 1199px) {
    header {
      position: fixed;
      background-color: #fff;
      color: #222222; }
      header .container-fluid {
        margin-top: 50px; }
      header .logo {
        width: 80px;
        height: 80px;
        top: 4px; }
      header .location, header .phones, header .email {
        color: #222222; }
        header .location:after, header .phones:after, header .email:after {
          color: #ffe400; }
        header .location a, header .phones a, header .email a {
          color: #222222; }
        header .location ul li a, header .phones ul li a, header .email ul li a {
          color: #222222; }
      header .email {
        top: 12px;
        right: 50px; }
        header .email:hover {
          border-bottom: 1px dashed #c5c6c6; }
      header .socials, header .location {
        display: none; }
      header .phones {
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(255, 228, 0, 0.9);
        padding: 0;
        text-align: center;
        box-sizing: border-box;
        padding: 15px;
        z-index: 1; }
        header .phones:after {
          display: none; }
      header .phones--btn {
        display: block;
        position: absolute;
        top: 12px;
        right: 15px;
        font-family: 'fontAwesome';
        font-size: 25px;
        color: #ffe400; }
        header .phones--btn:after {
          content: '\f095'; }
      header .nav {
        display: none; }
        header .nav:after {
          display: none; }
        header .nav > ul > li {
          display: block;
          padding: 0 8px;
          margin: 0px; }
          header .nav > ul > li > a {
            display: table; }
          header .nav > ul > li:first-child {
            margin-top: 35px; }
          header .nav > ul > li:nth-child(4) {
            margin-right: 0; }
          header .nav > ul > li:last-child {
            margin: 0; }
          header .nav > ul > li ul {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: none; }
            header .nav > ul > li ul li a {
              white-space: inherit;
              padding-left: 5px;
              padding-right: 5px; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 15px;
        top: 4px;
        padding: 0;
        margin: 0;
        outline: none;
        float: none; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #000;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); }
      header .langs {
        right: auto;
        left: 12px;
        top: -38px; }
      header .search--btn {
        top: -35px;
        right: auto;
        left: 70px; }
        header .search--btn.active {
          color: #000; }
      header .search {
        width: 100%;
        right: 0; } }
  @media screen and (max-width: 768px) {
    header .email {
      width: 22px;
      height: 22px;
      overflow: hidden;
      color: transparent !important;
      top: 10px;
      right: auto;
      left: 15px; }
      header .email:after {
        left: 0;
        font-size: 22px; }
    header .langs {
      top: 4px;
      left: auto;
      right: 35px; }
    header .search--btn {
      top: 7px;
      left: auto;
      right: 10px; } }

/* header */
.preload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffe400;
  z-index: 999; }
  .preload .preload__block {
    display: table;
    width: 100%;
    height: 100%;
    position: relative; }
    .preload .preload__block .preload__block__img {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center; }
      .preload .preload__block .preload__block__img .preload__block__img__bg {
        width: 250px;
        animation-name: preloadBg;
        animation-direction: alternate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        opacity: 0.7; }
      .preload .preload__block .preload__block__img .preload__block__img__logo {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -100px auto 0;
        animation-name: preloadLogo;
        animation-direction: normal;
        animation-duration: 3s;
        animation-iteration-count: infinite; }

@media screen and (min-width: 1200px) {
  [data-lang="kz"] header .nav > ul > li {
    margin: 0 20px 0 0; }
    [data-lang="kz"] header .nav > ul > li:nth-child(4) {
      margin-right: 150px; } }

@keyframes preloadBg {
  0% {
    transform: scale(1);
    opacity: 0.3; }
  25% {
    transform: scale(1.2);
    opacity: 1; }
  50% {
    transform: scale(1);
    opacity: 0.3; }
  75% {
    transform: scale(1.2);
    opacity: 1; }
  0% {
    transform: scale(1);
    opacity: 0.3; } }

@keyframes preloadLogo {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(360deg);
    margin-top: -100px; }
  85% {
    margin-top: -120px; }
  88% {
    margin-top: -100px; }
  91% {
    margin-top: -120px; }
  94% {
    margin-top: -100px; }
  97% {
    margin-top: -110px; }
  100% {
    margin-top: -100px;
    transform: rotate(360deg); } }

/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
    .slider .slick-slide img {
      width: 100%;
      height: auto;
      display: block; }
    .slider .slick-slide .slider__block {
      width: 845px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      padding: 50px;
      margin: 0 auto;
      margin-top: -120px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__block__title {
        display: block;
        font-family: "opensans_bold";
        font-size: 42px;
        color: #fff;
        line-height: 1;
        padding-bottom: 15px;
        margin-bottom: 20px;
        position: relative; }
        .slider .slick-slide .slider__block .slider__block__title:after {
          content: '';
          height: 4px;
          width: 65px;
          background-color: #ffe400;
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          margin: 0 auto; }
      .slider .slick-slide .slider__block .slider__block__slogan {
        font-size: 32px; }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      margin: 0px 2px; }
      .slider .slick-dots li button {
        background-color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        transition: 300ms; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:hover {
          background-color: #ffe400; }
      .slider .slick-dots li.slick-active button {
        background-color: #ffe400; }
  @media screen and (max-width: 1440px) {
    .slider .slick-slide .slider__block .slider__block__title {
      font-size: 45px; }
    .slider .slick-slide .slider__block .slider__block__slogan {
      font-size: 28px; } }
  @media screen and (max-width: 1199px) {
    .slider .slick-slide .slider__block {
      padding: 25px; }
      .slider .slick-slide .slider__block .slider__block__title {
        font-size: 40px; } }
  @media screen and (max-width: 991px) {
    .slider {
      margin-bottom: 0 !important; }
      .slider .slick-slide .slider__block {
        width: 96%;
        padding: 25px;
        margin: 0 auto;
        top: 35px; }
        .slider .slick-slide .slider__block .slider__block__title {
          font-size: 30px; }
        .slider .slick-slide .slider__block .slider__block__slogan {
          font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide .slider__block {
      top: 35px; }
      .slider .slick-slide .slider__block .slider__block__title {
        font-size: 22px; }
      .slider .slick-slide .slider__block .slider__block__slogan {
        font-size: 18px; }
    .slider .slick-dots {
      bottom: 15px; } }
  @media screen and (max-width: 680px) {
    .slider .slick-slide .slider__block {
      padding: 15px; }
    .slider .slick-slide img {
      height: 296px;
      width: auto; } }

/* main slider */
/* main services */
.services {
  position: relative;
  padding-top: 30px; }
  .services .container {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    padding: 0 15px;
    z-index: 1; }
  .services .services__img {
    width: 620px;
    height: 620px;
    display: block;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin: -25px auto 0; }
    .services .services__img .services__img__bg {
      width: 620px;
      animation-name: servicesImg;
      animation-direction: alternate;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      opacity: 0.7; }
    .services .services__img .services__img__logo {
      width: 360px;
      height: 360px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: -180px auto 0;
      /* 	animation-name: servicesLogo;
		animation-direction: normal;
		animation-duration: 3s;
		animation-iteration-count: infinite; */ }
  .services .services__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;
    margin-top: 50px; }
    .services .services__list li {
      display: block;
      margin: 50px 0; }
      .services .services__list li .services__list__col {
        max-width: 40%;
        padding: 0 0 0 55px;
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top; }
        .services .services__list li .services__list__col:after {
          content: '\f00c';
          width: 20px;
          height: 20px;
          font-family: 'fontAwesome';
          font-size: 15px;
          color: #949494;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 100%;
          background-color: #ffe400;
          line-height: 20px;
          border: 1px solid #949494; }
        .services .services__list li .services__list__col:before {
          content: '';
          width: 2px;
          background-color: #949494;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 40px; }
        .services .services__list li .services__list__col a:hover {
          color: #222;
          text-decoration: underline !important; }
      .services .services__list li:nth-child(1) .services__list__col:first-child {
        margin-right: 45px; }
      .services .services__list li:nth-child(2) .services__list__col:first-child {
        margin-right: 220px; }
      .services .services__list li:nth-child(3) .services__list__col:first-child {
        margin-right: 260px; }
      .services .services__list li:nth-child(4) .services__list__col:first-child {
        margin-right: 200px; }
  @media screen and (max-width: 1199px) {
    .services .services__list li:nth-child(1) .services__list__col:first-child {
      margin-right: 25px; }
    .services .services__list li:nth-child(2) .services__list__col:first-child {
      margin-right: 150px; }
    .services .services__list li:nth-child(3) .services__list__col:first-child {
      margin-right: 180px; } }
  @media screen and (max-width: 991px) {
    .services {
      padding-bottom: 25px; }
      .services .container {
        position: relative;
        top: 0; }
      .services .services__list {
        position: relative;
        text-align: left;
        margin-top: 0; }
        .services .services__list li {
          margin: 0; }
          .services .services__list li .services__list__col {
            display: block;
            margin-right: 0 !important;
            text-align: left;
            margin-bottom: 15px; }
            .services .services__list li .services__list__col a {
              font-size: 16px; }
      .services .services__img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 70px; }
        .services .services__img .services__img__bg {
          width: 500px;
          margin: 0 auto; } }
  @media screen and (max-width: 768px) {
    .services .services__img {
      display: none; } }

@keyframes servicesImg {
  0% {
    transform: scale(1.2);
    opacity: 0; }
  10% {
    transform: scale(1);
    opacity: 0.3; }
  25% {
    transform: scale(1.2);
    opacity: 0; }
  40% {
    transform: scale(1);
    opacity: 0.3; }
  55% {
    transform: scale(1.2);
    opacity: 0; }
  70% {
    transform: scale(1);
    opacity: 0.3; } }

@keyframes servicesLogo {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: scale(1.2); }
  20% {
    transform: scale(1); }
  25% {
    margin-top: -200px; }
  30% {
    margin-top: -180px; }
  33% {
    margin-top: -200px; }
  38% {
    margin-top: -180px; }
  41% {
    margin-top: -190px; }
  44% {
    margin-top: -180px; }
  75% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* main services */
/* main about */
.about {
  position: relative;
  color: #fff; }
  .about:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .about .container {
    position: relative;
    z-index: 1;
    padding-top: 35px;
    padding-bottom: 35px; }
  .about h1 {
    text-transform: uppercase;
    color: #fff; }
  .about img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center center; }
  @media screen and (max-width: 1650px) {
    .about {
      overflow: hidden; }
      .about .container {
        padding-top: 25px; }
      .about > img {
        width: auto;
        height: 507px; } }
  @media screen and (max-width: 1199px) {
    .about h1 {
      font-size: 40px; } }
  @media screen and (max-width: 991px) {
    .about h1 {
      font-size: 32px;
      margin-top: 0; }
    .about .container {
      position: relative;
      top: 0; }
    .about > img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; } }
  @media screen and (max-width: 768px) {
    .about h1 {
      font-size: 28px; } }

/* main about */
/* main partners */
.partners table {
  width: 100%;
  margin-top: 25px; }
  .partners table tr td {
    border: 1px solid #d6dde3; }
    .partners table tr td img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .partners table tr td:first-child {
      border-left: 1px solid transparent; }
    .partners table tr td:last-child {
      border-right: 1px solid transparent; }
  .partners table tr:first-child td {
    border-top: 1px solid transparent; }
  .partners table tr:last-child td {
    border-bottom: 1px solid transparent; }

@media screen and (max-width: 768px) {
  .partners table tr td {
    width: 33.33%;
    display: inline-block;
    vertical-align: top; }
    .partners table tr td:nth-child(1), .partners table tr td:nth-child(4) {
      border-left: none; }
    .partners table tr td:nth-child(3), .partners table tr td:nth-child(6) {
      border-right: none; } }

/* main partners */
/* main news */
.news .news__col {
  display: block; }
  .news .news__col .news__col__img {
    overflow: hidden;
    position: relative; }
    .news .news__col .news__col__img img {
      display: block;
      width: 100%;
      filter: grayscale(100%);
      transform-origin: center center;
      transition: 300ms; }
  .news .news__col .news__col__block {
    padding: 30px;
    border: 1px solid #dadada; }
    .news .news__col .news__col__block h5 {
      min-height: 57px;
      margin: 0 0 15px 0; }
    .news .news__col .news__col__block p {
      min-height: 125px;
      color: #222222; }
  .news .news__col .news__col__date {
    display: block;
    height: 40px;
    box-sizing: border-box;
    background-color: #e5e5e5;
    color: #222222;
    font-family: "opensans_bold";
    font-size: 16px;
    padding: 10px 10px 5px 55px;
    line-height: 1;
    position: relative;
    transition: 300ms; }
    .news .news__col .news__col__date:after {
      content: '\f017';
      font-family: 'fontAwesome';
      font-size: 20px;
      position: absolute;
      top: 8px;
      left: 30px;
      color: #222222; }
  .news .news__col:hover {
    text-decoration: none; }
    .news .news__col:hover .news__col__img img {
      filter: grayscale(0);
      transform: scale(1.1); }
    .news .news__col:hover .news__col__block h5 {
      text-decoration: underline; }
    .news .news__col:hover .news__col__date {
      background-color: #ffe400; }

@media screen and (max-width: 991px) {
  .news .news__col {
    margin-bottom: 15px; }
    .news .news__col .news__col__img img {
      filter: grayscale(0%); }
    .news .news__col .news__col__block {
      padding: 15px; }
      .news .news__col .news__col__block h5, .news .news__col .news__col__block p {
        min-height: 1px; }
    .news .news__col .news__col__date {
      background-color: #ffe400; } }

/* main news */
.btn--up {
  width: 35px;
  height: 35px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-family: 'fontAwesome';
  font-size: 20px !important;
  background-color: #333;
  color: #ffe400 !important;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 5px;
  z-index: 1;
  opacity: 0; }
  .btn--up:after {
    content: '\f077'; }
  .btn--up:hover {
    padding-top: 2px; }
  @media screen and (max-width: 1199px) {
    .btn--up {
      display: none; } }

/* main content */
/* page content */
.page {
  padding-top: 118px;
  font-size: 15px;
  background-size: 1024px; }
  .page.company {
    background-image: none; }
  .page .news1 .date, .page .news .date {
    font-size: 15px;
    color: #000; }
  .page .news1 a, .page .news a {
    text-decoration: underline; }
    .page .news1 a:hover, .page .news a:hover {
      color: #ffe400;
      text-decoration: none; }
  .page .page_nav .active {
    color: #ffe400; }
  .page h1 {
    font-size: 28px; }
  .page h3 {
    text-transform: uppercase;
    margin-bottom: 25px; }
  .page h4 {
    text-align: center;
    margin: 25px 0; }
  .page a {
    color: #222222;
    transition: 300ms; }
    .page a:active, .page a:focus {
      text-decoration: none; }
    .page a:hover {
      color: #ffe400;
      text-decoration: underline; }
  .page p, .page a {
    font-size: 15px; }
  .page .quotes {
    position: relative;
    padding: 5px 0 5px 50px;
    overflow: hidden;
    font-family: "OpenSansItalic";
    font-weight: bold;
    margin: 25px 0; }
    .page .quotes:after {
      content: '';
      width: 23px;
      height: 21px;
      position: absolute;
      top: 0;
      left: 10px;
      font-size: 20px;
      background: url(../img/quotes.png) no-repeat center center; }
  .page .services .services__list {
    margin-top: 40px; }
    .page .services .services__list .services__list__col a {
      font-size: 20px !important;
      text-decoration: none !important; }
  .page .company--img {
    max-width: 530px;
    position: relative;
    margin: 0 auto; }
    .page .company--img img {
      width: 100%; }
    .page .company--img:after {
      content: '';
      position: absolute;
      width: 150%;
      height: 150%;
      background: url(../img/img-37.png) no-repeat center center;
      background-size: contain;
      top: 50%;
      left: 50%;
      margin-top: -75%;
      margin-left: -75%;
      animation-name: servicesImg;
      animation-direction: alternate;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      opacity: 1; }
  @media screen and (max-width: 1199px) {
    .page {
      padding-top: 0; } }
  @media screen and (max-width: 1170px) {
    .page {
      font-size: 16px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 991px) {
    .page h1 {
      font-size: 24px; }
    .page p, .page a {
      font-size: 16px; }
    .page .news1 .date, .page .news .date {
      font-size: 16px;
      color: #ffe400; }
    .page .company--img:after {
      width: 110%;
      height: 110%;
      margin: -55% 0 0 -55%; } }
  @media screen and (max-width: 768px) {
    .page {
      padding-bottom: 25px; }
      .page .company--img {
        max-width: 100%; }
        .page .company--img:after {
          display: none; }
      .page .gallery--height td img {
        width: 100%;
        height: auto;
        margin: 0 auto; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 16px;
  margin: 40px 0 0 0; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #7d7d7d;
      line-height: 1;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #454545;
        font-size: 16px; }
      .breadcrumbs ul li:after {
        content: '/';
        margin: 0px 2px 0px 7px;
        color: #454545;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 767px) {
    .breadcrumbs {
      font-size: 12px; }
      .breadcrumbs ul li:after {
        font-size: 12px;
        margin-top: 1px; }
      .breadcrumbs ul li a {
        font-size: 12px; } }

/* breadcrumbs */
/* page about */
.about__check--list {
  text-align: center; }
  .about__check--list .check--list {
    width: 400px;
    margin: 0 5px 0 5px;
    display: inline-block;
    vertical-align: top; }
  @media screen and (max-width: 768px) {
    .about__check--list .check--list {
      width: 100%; } }

/* page about */
/* page services */
.services__col {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  margin-bottom: 30px; }
  .services__col img {
    display: block;
    width: 100%;
    filter: grayscale(100%);
    transition: 350ms; }
  .services__col .services__col__title {
    display: table;
    width: 100%;
    height: 58px;
    padding: 5px 5px;
    background-color: black;
    position: relative;
    text-align: center;
    overflow: hidden; }
    .services__col .services__col__title h5 {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      font-family: "opensans_bold";
      font-size: 18px;
      color: #ffe400;
      margin: 0 auto; }
  .services__col .btn {
    display: none;
    width: 170px !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: -20px auto 0;
    opacity: 0; }
  .services__col:hover .btn {
    opacity: 0.8; }
  .services__col:hover img {
    filter: grayscale(0);
    transform: scale(1.1); }
  .services__col.last {
    padding-top: 50px;
    pointer-events: none; }
    .services__col.last img {
      width: auto;
      max-width: 100%;
      filter: grayscale(0);
      margin: 0 auto; }
  @media screen and (max-width: 991px) {
    .services__col .services__col__title h5 {
      min-height: 0px; }
    .services__col img {
      filter: grayscale(0%); }
    .services__col .btn {
      opacity: 0.8; } }

/* page services */
/* page services unit */
.services--unit__for {
  position: relative; }
  .services--unit__for:after {
    content: '\f00e';
    font-family: 'fontAwesome';
    font-size: 25px;
    color: #fff;
    position: absolute;
    right: 20px;
    bottom: 20px; }
  .services--unit__for .slick-slide {
    outline: none;
    overflow: hidden; }
    .services--unit__for .slick-slide img {
      display: block;
      width: 100%; }

.services--unit__nav {
  margin: 30px -10px 30px;
  padding: 0 115px; }
  .services--unit__nav .slick-slide {
    height: 90px;
    margin: 0 10px;
    position: relative;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    border: 2px solid transparent;
    transition: 300ms; }
    .services--unit__nav .slick-slide:hover, .services--unit__nav .slick-slide.slick-current {
      border: 2px solid #ffe400; }
    .services--unit__nav .slick-slide img {
      display: block;
      width: 100%; }
  .services--unit__nav .slick-arrow {
    display: block;
    width: 35px;
    height: 35px;
    padding: 0px;
    overflow: hidden;
    transition: 350ms;
    line-height: 1;
    z-index: 1; }
    .services--unit__nav .slick-arrow:before {
      display: none; }
    .services--unit__nav .slick-arrow:after {
      display: block;
      font-family: 'fontAwesome';
      font-size: 35px;
      color: #0f0f0f;
      text-align: center;
      transition: 300ms; }
    .services--unit__nav .slick-arrow.slick-prev {
      left: 65px; }
      .services--unit__nav .slick-arrow.slick-prev:after {
        content: '\f104';
        margin-left: -2px; }
    .services--unit__nav .slick-arrow.slick-next {
      right: 65px; }
      .services--unit__nav .slick-arrow.slick-next:after {
        content: '\f105';
        margin-right: -2px; }
    .services--unit__nav .slick-arrow:hover:after {
      color: #ffe400; }
  @media screen and (max-width: 1199px) {
    .services--unit__nav {
      padding: 0 50px; }
      .services--unit__nav .slick-arrow.slick-prev {
        left: 10px; }
      .services--unit__nav .slick-arrow.slick-next {
        right: 10px; } }
  @media screen and (max-width: 991px) {
    .services--unit__nav .slick-slide {
      height: 80px; } }
  @media screen and (max-width: 767px) {
    .services--unit__nav .slick-slide {
      height: 120px; } }
  @media screen and (max-width: 640px) {
    .services--unit__nav .slick-slide {
      height: 90px; } }
  @media screen and (max-width: 480px) {
    .services--unit__nav .slick-slide {
      height: 60px; } }
  @media screen and (max-width: 380px) {
    .services--unit__nav .slick-slide {
      height: 45px;
      margin: 0 5px; } }

.cat--unit__info {
  padding: 60px 50px;
  background-color: #f5f5f5;
  color: #454545;
  font-size: 17px; }
  .cat--unit__info h4 {
    margin: 0 0 15px 0;
    text-align: left;
    color: #454545; }
  .cat--unit__info ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0; }
    .cat--unit__info ul:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #e0e0e0;
      margin: 35px 0; }
    .cat--unit__info ul li {
      position: relative;
      padding-right: 70px;
      margin: 15px 0; }
      .cat--unit__info ul li:after {
        content: '';
        height: 1px;
        background-color: #c3c1c1;
        position: absolute;
        left: 0;
        right: 0;
        top: 21px; }
      .cat--unit__info ul li strong {
        display: inline;
        position: relative;
        background-color: #f5f5f5;
        z-index: 1; }
      .cat--unit__info ul li span {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f5f5f5;
        z-index: 1;
        display: inline-block;
        vertical-align: top; }
    .cat--unit__info ul:last-child:after {
      display: none; }
  @media screen and (max-width: 1199px) {
    .cat--unit__info {
      padding: 30px 25px; } }
  @media screen and (max-width: 991px) {
    .cat--unit__info {
      padding: 20px 15px; } }

.video {
  padding-top: 25px; }
  .video h3 {
    text-align: center; }

/* page services unit */
/* page pagination */
.pagination ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .pagination ul li {
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top; }
    .pagination ul li a {
      text-decoration: none;
      width: 40px;
      height: 40px;
      display: block;
      border: 1px solid transparent;
      font-size: 16px;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 2px 0px;
      outline: none;
      color: #222222;
      border: 1px solid #ffe400;
      line-height: 1; }
      .pagination ul li a:hover {
        background-color: #ffe400;
        border: 1px solid #ffe400;
        color: #222222; }
    .pagination ul li.active a {
      background-color: #ffe400;
      border: 1px solid #ffe400; }
    .pagination ul li.prev a, .pagination ul li.next a {
      position: relative;
      border: 1px solid transparent;
      background-color: transparent !important;
      transition: 0ms; }
      .pagination ul li.prev a:hover, .pagination ul li.next a:hover {
        border: 1px solid transparent;
        background-color: transparent !important;
        color: #ffe400; }
      .pagination ul li.prev a:after, .pagination ul li.next a:after {
        display: block;
        font-family: 'fontAwesome';
        font-size: 22px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: -12px auto;
        transition: 350ms; }
    .pagination ul li.prev a:after {
      content: '\f104'; }
    .pagination ul li.next a:after {
      content: '\f105'; }

/* page pagination */
/* page contacts */
.contacts__left {
  font-size: 18px; }
  .contacts__left > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .contacts__left > ul li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 15px; }
      .contacts__left > ul li:after {
        position: absolute;
        top: 1px;
        left: 0;
        color: #ffe400;
        font-family: 'fontAwesome';
        font-size: 24px; }
      .contacts__left > ul li.location:after {
        content: '\f041'; }
      .contacts__left > ul li.phones:after {
        content: '\f095'; }
      .contacts__left > ul li.email:after {
        content: '\f0e0';
        font-size: 18px;
        top: 3px; }
      .contacts__left > ul li a {
        text-decoration: none;
        font-size: 18px; }
  .contacts__left .socials {
    margin-top: 25px; }

.form {
  display: block; }
  .form input[type="text"],
  .form input[type="email"],
  .form input[type="password"],
  .form textarea {
    display: block;
    width: 100%;
    background-color: transparent;
    margin-bottom: 10px; }
  .form .btn {
    font-family: "opensans_bold";
    float: right; }

@media screen and (max-width: 991px) {
  .map > ymaps {
    height: 250px !important; } }

.ymaps-2-1-62-map {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: grayscale(100%); }

/* page contacts */
/* modal */
.modal .modal-content {
  border-radius: 0px; }
  .modal .modal-content h4 {
    margin: 0; }
  .modal .modal-content .close {
    position: absolute;
    width: 25px;
    height: 25px;
    font-family: 'FontAwesome';
    font-size: 25px;
    opacity: 1;
    right: 10px;
    top: 10px;
    outline: none; }
    .modal .modal-content .close:after {
      content: '\f00d'; }
    .modal .modal-content .close:hover {
      opacity: 0.7; }
  .modal .modal-content .btn {
    background-color: #ff0000;
    width: 100% !important; }

@media screen and (max-width: 991px) {
  .modal .modal-content h3 {
    font-size: 30px; } }

@media screen and (max-width: 768px) {
  .modal .modal-content h3 {
    font-size: 20px; } }

#modal--map {
  width: 600px; }
  @media screen and (max-width: 991px) {
    #modal--map {
      width: 100%;
      height: 300px;
      overflow: hidden; }
      #modal--map > ymaps {
        height: 100% !important; } }

/* modal */
/* page content */
/* footer */
footer {
  background-color: #333333;
  color: #fff;
  padding-top: 30px;
  font-size: 15px; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  footer h6, footer h2 {
    font-family: "OpenSansRegular";
    font-size: 16px;
    color: #fff;
    text-align: left;
    margin-bottom: 25px; }
  footer .footer__col {
    min-height: 195px; }
    footer .footer__col ul li {
      display: block;
      margin-bottom: 5px; }
      footer .footer__col ul li a {
        color: #fff; }
        footer .footer__col ul li a:hover {
          text-decoration: underline; }
      footer .footer__col ul li.active a {
        text-decoration: underline; }
    footer .footer__col.col--nav {
      padding: 0 60px;
      border-left: 1px solid #999999;
      border-right: 1px solid #999999;
      margin-left: 15px; }
    footer .footer__col.col--contacts {
      padding: 0 30px;
      margin: 0 30px 0 0;
      border-right: 1px solid #999999; }
      footer .footer__col.col--contacts ul li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px; }
        footer .footer__col.col--contacts ul li:after {
          position: absolute;
          top: 1px;
          left: 0;
          color: #fff;
          font-family: 'fontAwesome';
          font-size: 20px; }
        footer .footer__col.col--contacts ul li.location:after {
          content: '\f041'; }
        footer .footer__col.col--contacts ul li.phones:after {
          content: '\f095'; }
        footer .footer__col.col--contacts ul li.email:after {
          content: '\f0e0';
          font-size: 16px; }
        footer .footer__col.col--contacts ul li a {
          display: block; }
    footer .footer__col.col--socials ul li {
      display: inline-block;
      vertical-align: top; }
  footer .container-fluid {
    background-color: #f9f2e2;
    padding: 15px 0;
    margin-top: 25px; }
    footer .container-fluid p {
      color: #454545;
      margin: 0;
      display: inline-block;
      vertical-align: top; }
    footer .container-fluid .artmedia {
      float: right;
      position: relative;
      padding-right: 120px;
      width: 250px;
      display: inline-block;
      vertical-align: top; }
      footer .container-fluid .artmedia img {
        position: absolute;
        top: -3px;
        right: 0; }
  @media screen and (max-width: 1199px) {
    footer .footer__col.col--nav {
      padding: 0 30px; }
    footer .footer__col.col--contacts {
      padding: 0; } }
  @media screen and (max-width: 991px) {
    footer {
      padding-top: 15px; }
      footer .container-fluid {
        margin-top: 0px; }
      footer h6, footer h2 {
        margin-top: 15px;
        margin-bottom: 15px; }
      footer .footer__col {
        min-height: 1px;
        margin-bottom: 15px; }
        footer .footer__col.col--nav, footer .footer__col.col--contacts {
          padding: 0;
          margin: 0;
          border: none; }
        footer .footer__col .footer--logo {
          display: block;
          width: 120px; }
          footer .footer__col .footer--logo img {
            width: 100%; } }
  @media screen and (max-width: 520px) {
    footer > .container {
      text-align: center; }
    footer h2 {
      text-align: center; }
    footer .footer__col .footer--logo {
      margin: 0 auto; }
    footer .footer__col ul li {
      text-align: left;
      display: table;
      margin: 2px auto; }
    footer .footer__col .socials ul li {
      margin: 2px 5px; }
    footer .container-fluid p {
      display: block;
      text-align: center; }
    footer .container-fluid .artmedia {
      display: block;
      float: none;
      margin: 5px auto 0; } }

/* footer */
