/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}
		img {
			width: 100%;
			height: auto;
			display: block;
		}
		.slider__block {
			width: 845px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			padding: 50px;
			margin: 0 auto;
			margin-top: -120px;
			text-align: center;
			background-color: rgba(0,0,0,0.3);
			color: #fff;
			z-index: 1;
			.slider__block__title {
				display: block;
				font-family: $bold;
				font-size: 42px;
				color: #fff;
				line-height: 1;
				padding-bottom: 15px;
				margin-bottom: 20px;
				position: relative;
				&:after {
					content: '';
					height: 4px;
					width: 65px;
					background-color: #ffe400;
					position: absolute;
					bottom: 0px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
			.slider__block__slogan {
				font-size: 32px;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				background-color: #fff;
				width: 16px;
				height: 16px;
				border-radius: 100%;
				transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #ffe400;
				}
			}
			&.slick-active {
				button {
					background-color: #ffe400;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 45px;
				}
				.slider__block__slogan {
					font-size: 28px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.slick-slide .slider__block {
			padding: 25px;
			.slider__block__title {
				font-size: 40px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		margin-bottom: 0!important;
		.slick-slide .slider__block {
			width: 96%;
			padding: 25px;
			margin: 0 auto;
			top: 35px;
			.slider__block__title {
				font-size: 30px;
			}
			.slider__block__slogan {
				font-size: 24px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide .slider__block {
			top: 35px;
			.slider__block__title {
				font-size: 22px;
			}
			.slider__block__slogan {
				font-size: 18px;
			}
		}
		.slick-dots {
			bottom: 15px;
		}
	}
	@media screen and (max-width: 680px) {
		.slick-slide {
			.slider__block {
				padding: 15px;
			}
			img {
				height: 296px;
				width: auto;
			}
		}
	}
}
/* main slider */

/* main services */
.services {
	position: relative;
	padding-top: 30px;
	.container {
		position: absolute;
		top: 25px;
		left: 0;
		right: 0;
		padding: 0 15px;
		z-index: 1;
	}
	.services__img {
		width: 620px;
		height: 620px;
		display: block;
		text-align: center;
		overflow: hidden;
		position: relative;
		margin: -25px auto 0;
		.services__img__bg {
			width: 620px;
			animation-name: servicesImg;
			animation-direction: alternate;
			animation-duration: 3s;
			animation-iteration-count: infinite;
			opacity: 0.7;
		}
		.services__img__logo {
			width: 360px;
			height: 360px;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: -180px auto 0;
		/* 	animation-name: servicesLogo;
		animation-direction: normal;
		animation-duration: 3s;
		animation-iteration-count: infinite; */
		}
	}
	.services__list {
		@include ul-default;
		display: block;
		text-align: center;
		margin-top: 50px;
		li {
			display: block;
			margin: 50px 0;
			.services__list__col {
				max-width: 40%;
				padding: 0 0 0 55px;
				position: relative;
				box-sizing: border-box;
				@include inline-block;
				&:after {
					content: '\f00c';
					width: 20px;
					height: 20px;
					font-family: 'fontAwesome';
					font-size: 15px;
					color: #949494;
					text-align: center;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 100%;
					background-color: #ffe400;
					line-height: 20px;
					border: 1px solid #949494;
				}
				&:before {
					content: '';
					width: 2px;
					background-color: #949494;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 40px;
				}
				a {
					&:hover {
						color: #222;
						text-decoration: underline!important;
					}
				}
			}
			&:nth-child(1) {
				.services__list__col:first-child {
					margin-right: 45px;
				}
			}
			&:nth-child(2) {
				.services__list__col:first-child {
					margin-right: 220px;
				}
			}
			&:nth-child(3) {
				.services__list__col:first-child {
					margin-right: 260px;
				}
			}
			&:nth-child(4) {
				.services__list__col:first-child {
					margin-right: 200px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.services__list {
			li {
				&:nth-child(1) .services__list__col:first-child {
					margin-right: 25px;
				}
				&:nth-child(2) .services__list__col:first-child {
					margin-right: 150px;
				}
				&:nth-child(3) .services__list__col:first-child {
					margin-right: 180px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 25px;
		.container {
			position: relative;
			top: 0;
		}
		.services__list {
			position: relative;
			text-align: left;
			margin-top: 0;
			li {
				margin: 0;
				.services__list__col {
					display: block;
					margin-right: 0!important;
					text-align: left;
					margin-bottom: 15px;
					a {
						font-size: 16px;
					}
				}
			}
		}
		.services__img {
			width: 100%;
			height: auto;
			position: absolute;
			top: 70px;
			.services__img__bg {
				width: 500px;
				margin: 0 auto;
			}
		}
	}
	@media screen and (max-width: 768px) {
		// background: url(../img/img-4.png) no-repeat center center;
		// background-size: contain;
		.services__img {
			display: none;
		}
	}
}
@keyframes servicesImg {
	0% {
		transform: scale(1.2);
		opacity: 0;
	}
	10% {
		transform: scale(1);
		opacity: 0.3;
	}
	25% {
		transform: scale(1.2);
		opacity: 0;
	}
	40% {
		transform: scale(1);
		opacity: 0.3;
	}
	55% {
		transform: scale(1.2);
		opacity: 0;
	}
	70% {
		transform: scale(1);
		opacity: 0.3;
	}
	
}
@keyframes servicesLogo {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: scale(1.2);
	}
	20% {
		transform: scale(1);
	}
	25% {
		margin-top: -200px;
	}
	30% {
		margin-top: -180px;
	}
	33% {
		margin-top: -200px;
	}
	38% {
		margin-top: -180px;
	}
	41% {
		margin-top: -190px;
	}
	44% {
		margin-top: -180px;
	}
	75% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* main services */

/* main about */
.about {
	position: relative;
	color: #fff;
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
	}
	.container {
        position: relative;
		z-index: 1;
        padding-top: 35px;
        padding-bottom: 35px;
	}
	h1 {
		text-transform: uppercase;
		color: #fff;
	}
	img {
		display: block;
		width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
        object-position: center center;

	}
	@media screen and (max-width: 1650px) {
		overflow: hidden;
		.container {
			padding-top: 25px;
		}
		> img {
			width: auto;
			height: 507px;
		}
	}
	@media screen and (max-width: 1199px) {
		h1 {
			font-size: 40px;
		}
	}
	@media screen and (max-width: 991px) {
		h1 {
			font-size: 32px;
			margin-top: 0;
		}
		.container {
			position: relative;
			top: 0;
		}
		> img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		h1 {
			font-size: 28px;
		}
	}
}
/* main about */

/* main partners */
.partners {
	table {
		width: 100%;
		margin-top: 25px;
		tr {
			td {
				border: 1px solid #d6dde3;
				img {
					display: block;
					max-width: 100%;
					margin: 0 auto;
				}
				&:first-child {
					border-left: 1px solid transparent;
				}
				&:last-child {
					border-right: 1px solid transparent;
				}
			}
			&:first-child {
				td {
					border-top: 1px solid transparent;
				}
			}
			&:last-child {
				td {
					border-bottom: 1px solid transparent;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		table {
			tr {
				td {
					width: 33.33%;
					@include inline-block;
					&:nth-child(1),
					&:nth-child(4) {
						border-left: none;
					}
					&:nth-child(3),
					&:nth-child(6) {
						border-right: none;
					}
				}
			}
		}
	}
}
/* main partners */

/* main news */
.news {
	.news__col {
		display: block;
		.news__col__img {
			overflow: hidden;
			position: relative;
			img {
				display: block;
				width: 100%;
				filter: grayscale(100%);
				transform-origin: center center;
				transition: 300ms;
			}
		}
		.news__col__block {
			padding: 30px;
			border: 1px solid #dadada;
			h5 {
				min-height: 57px;
				margin: 0 0 15px 0;
			}
			p {
				min-height: 125px;
				color: #222222;
			}
		}
		.news__col__date {
			display: block;
			height: 40px;
			box-sizing: border-box;
			background-color: #e5e5e5;
			color: #222222;
			font-family: $bold;
			font-size: 16px;
			padding: 10px 10px 5px 55px;
			line-height: 1;
			position: relative;
			transition: 300ms;
			&:after {
				content: '\f017';
				font-family: 'fontAwesome';
				font-size: 20px;
				position: absolute;
				top: 8px;
				left: 30px;
				color: #222222;
			}
		}
		&:hover {
			text-decoration: none;
			.news__col__img {
				img {
					filter: grayscale(0);
					transform: scale(1.1);
				}
			}
			.news__col__block {
				h5 {
					text-decoration: underline;
				}
			}
			.news__col__date {
				background-color: #ffe400;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col {
			margin-bottom: 15px;
			.news__col__img img {
				filter: grayscale(0%);
			}
			.news__col__block {
				padding: 15px;
				h5, p {
					min-height: 1px;
				}
			}
			.news__col__date {
				background-color: #ffe400;
			}
		}
	}
}
/* main news */

.btn--up {
	width: 35px;
	height: 35px;
	position: fixed;
	bottom: 25px;
	right: 25px;
	font-family: 'fontAwesome';
	font-size: 20px!important;
	background-color: #333;
	color: #ffe400!important;
	border-radius: 100%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 5px;
	z-index: 1;
	opacity: 0;
	&:after {
		content: '\f077';
	}
	&:hover {
		padding-top: 2px;
	}
	@media screen and (max-width: 1199px) {
		display: none;
	}
}

/* main content */